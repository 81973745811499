import { graphql } from '../../graphql/generated';
import { useGraphQLQuery } from '../../hooks/useGraphQLRequest';
import { type ResultOf } from '@graphql-typed-document-node/core';

const ExperienceSummaryCurrentUserGql = graphql(`
  query GetCurrentUserForExperienceSummaryView($id: Int!, $location_ids: [Int!]!) {
    me {
      id
      professional {
        markets {
          id
        }
      }
    }
  }
`);

const ExperienceSummaryProfessionalGql = graphql(`
  query GetProfessionalForExperienceSummaryView($id: Int!, $market_ids: [Int!]!) {
    professional(id: $id) {
      professional_positions {
        position {
          id
          ...ExperienceSummaryCardPosition
        }
      }
    }
  }
`);

const ExperienceSummaryViewWorkHistoriesGql = graphql(`
  query GetWorkHistoriesForExperienceSummaryView($input: WorkHistoryQueryInput) {
    workHistories(input: $input) {
      position_id
      ...ExperienceSummaryCardWorkHistory
    }
  }
`);

export const useExperienceSummaryViewQueries = () => {
  const { data: currentUserData, isLoading: currentUserIsLoading } = useGraphQLQuery<
    ResultOf<typeof ExperienceSummaryCurrentUserGql>
  >({
    operationName: 'GetCurrentUserForExperienceSummaryView',
    query: ExperienceSummaryCurrentUserGql,
    variables: {},
  });

  const currentUser = currentUserData?.me;

  const { data: professionalData, isLoading: professionalIsLoading } = useGraphQLQuery<
    ResultOf<typeof ExperienceSummaryProfessionalGql>
  >({
    operationName: 'GetProfessionalForExperienceSummaryView',
    query: ExperienceSummaryProfessionalGql,
    variables: {
      id: currentUser?.id,
      market_ids: currentUser?.professional?.markets?.map((market) => market.id),
    },
    enabled: !!currentUser,
  });

  const professional = professionalData?.professional;

  const { data: workHistoriesData, isLoading: workExperienceIsLoading } = useGraphQLQuery<
    ResultOf<typeof ExperienceSummaryViewWorkHistoriesGql>
  >({
    operationName: 'GetWorkHistoriesForExperienceSummaryView',
    query: ExperienceSummaryViewWorkHistoriesGql,
    variables: {},
  });
  const workHistories = workHistoriesData?.workHistories;

  const isLoading = currentUserIsLoading || professionalIsLoading || workExperienceIsLoading;

  return {
    professional,
    workHistories,
    isLoading,
  };
};
