import React from 'react';
import { Box, Form, Heading } from '@medely/ui-kit';
import { FixedFooterLayout } from '../../../../components/FixedFooterLayout/FixedFooterLayout';
import { WorkExperienceForm } from './WorkExperienceForm';
import { useApplicationMutations } from '../../../../hooks';
import useWorkExperienceInfoMutations from '../../../../hooks/useWorkExperienceInfoMutations';
import { ApplicationButtons } from '../../components/ApplicationButtons';
import { LoadingState } from '../../components/LoadingState';
import { createMostRecentWorkExperienceValidation } from './workExperienceSchema';
import { useWorkExperience, ValuesProps } from './useWorkExperience';
import { useGetWorkExperienceInitialData } from './useGetWorkExperienceInitialData';

export const WorkExperience = () => {
  const {
    currentUser,
    currentUserLoading,
    locationTypeOptions,
    locationTypesLoading,
    workHistory: workHistories,
    workHistoryLoading,
  } = useGetWorkExperienceInitialData();
  const {
    totalYearsOfExperience,
    positionsOptions,
    getInitialWorkExperienceValues,
    createPayload,
  } = useWorkExperience({ currentUser });
  const { submitWorkExperienceInfo } = useWorkExperienceInfoMutations();
  const { nextStep, prevStep, isLoading: mutationsLoading } = useApplicationMutations();

  const baseValuesLoading = currentUserLoading || locationTypesLoading || workHistoryLoading;

  const disableButtons = baseValuesLoading || mutationsLoading;

  const defaultValues = !!workHistories ? getInitialWorkExperienceValues(workHistories[0]) : {};

  const handleSubmit = (values: ValuesProps) => {
    const parsedValues = createPayload(values);
    submitWorkExperienceInfo(parsedValues, {
      onSuccess: () => {
        nextStep();
      },
    });
  };

  if (baseValuesLoading) {
    return <LoadingState />;
  }

  return (
    <>
      <Form
        handleSubmit={handleSubmit}
        schema={createMostRecentWorkExperienceValidation(totalYearsOfExperience)}
        defaultValues={defaultValues}
      >
        <FixedFooterLayout
          footerContent={<ApplicationButtons disabled={disableButtons} backClick={prevStep} />}
        >
          <Box pb={2}>
            <Heading size="s" color="text.primary">
              Add detail for your most recent paid clinical experience in the U.S.
            </Heading>
          </Box>
          <WorkExperienceForm
            positionsOptions={positionsOptions}
            // @ts-ignore
            locationTypeOptions={locationTypeOptions}
            currentUser={currentUser}
          />
        </FixedFooterLayout>
      </Form>
    </>
  );
};
