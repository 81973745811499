import React from 'react';
import { LoadingState } from '../../components/LoadingState';
import { Box, Form, Heading } from '@medely/ui-kit';
import { FixedFooterLayout } from '../../../../components/FixedFooterLayout';
import { ApplicationButtons } from '../../components/ApplicationButtons';
import { useWorkExperience, ValuesProps } from './useWorkExperience';
import { useGetWorkExperienceInitialData } from './useGetWorkExperienceInitialData';
import { createWorkExperienceValidation } from './workExperienceSchema';
import { WorkExperienceForm } from './WorkExperienceForm';
import useWorkExperienceInfoMutations from '../../../../hooks/useWorkExperienceInfoMutations';

export const AddMoreMonthsOfWorkExperience = () => {
  const {
    currentUser,
    locationTypeOptions,
    locationTypesLoading,
    currentUserLoading,
    workHistory,
    workHistoryLoading,
  } = useGetWorkExperienceInitialData();
  const { submitWorkExperienceInfo } = useWorkExperienceInfoMutations();

  const { getAddedMonths, createPayload } = useWorkExperience({ currentUser });

  const baseValuesLoading = currentUserLoading || locationTypesLoading || workHistoryLoading;

  const initialAddedMonths = !!workHistory
    ? getAddedMonths({
        startDate: workHistory[0].start_date,
        endDate: workHistory[0].end_date,
      })
    : 0;

  const recentPositionData = currentUser?.professional?.professional_positions?.find(
    (p: { has_recent_experience: boolean }) => p.has_recent_experience,
  );

  const positionId = recentPositionData?.position.id;
  const positionName = recentPositionData?.position.display_name;
  const crnPositionId = 25;
  const minMonthsNeeded = positionId === crnPositionId ? 6 : 8;
  const missingMonths = minMonthsNeeded - initialAddedMonths;

  const handleBack = () => {
    // add navigation to summary screen PRO-2555
  };

  const handleSubmit = (values: ValuesProps) => {
    const parsedValues = createPayload({ ...values, position_id: positionId });
    submitWorkExperienceInfo(parsedValues, {
      onSuccess: () => {
        // add navigation to summary screen PRO-2555
      },
    });
  };

  const disableButtons = baseValuesLoading;

  if (baseValuesLoading) {
    return <LoadingState />;
  }

  return (
    <>
      <Form handleSubmit={handleSubmit} schema={createWorkExperienceValidation(initialAddedMonths)}>
        <FixedFooterLayout
          footerContent={<ApplicationButtons disabled={disableButtons} backClick={handleBack} />}
        >
          <Box pb={2}>
            <Heading size="s" color="text.primary">
              Add at least {missingMonths} more months of recent {positionName} experience
            </Heading>
          </Box>
          <WorkExperienceForm
            locationTypeOptions={locationTypeOptions}
            currentUser={currentUser}
            positionId={positionId}
            hidePositionField={true}
          />
        </FixedFooterLayout>
      </Form>
    </>
  );
};
