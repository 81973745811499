import { Box, Card, HStack, Icon, InfoBadge, Label, Text, VStack } from '@medely/ui-kit';
import React from 'react';
import { FragmentType, graphql, parseFragmentData } from '../../graphql/generated';
import { MedelyDateTime } from '@medely/date-time-tools';
import { pluralize } from '../../utils';

const ExperienceSummaryCardWorkHistoryGql = graphql(`
  fragment ExperienceSummaryCardWorkHistory on WorkHistory {
    currently_work_here
    end_date
    start_date
  }
`);

const ExperienceSummaryCardPositionGql = graphql(`
  fragment ExperienceSummaryCardPosition on Position {
    display_name
    incrementality(market_ids: $market_ids)
  }
`);

interface Props {
  position: FragmentType<typeof ExperienceSummaryCardPositionGql>;
  workHistory?: FragmentType<typeof ExperienceSummaryCardWorkHistoryGql>;
}

const getWorkHistoryInfo = (
  inputWorkHistory?: FragmentType<typeof ExperienceSummaryCardWorkHistoryGql>,
) => {
  const workHistory = parseFragmentData(ExperienceSummaryCardWorkHistoryGql, inputWorkHistory);

  if (!workHistory) {
    return {
      hasWorkHistory: false,
    };
  }

  const startDate = new MedelyDateTime(workHistory?.start_date, { tz: 'system' });

  const endDate = workHistory?.end_date
    ? new MedelyDateTime(workHistory.end_date, { tz: 'system' })
    : MedelyDateTime.now({ tz: 'system' });
  const diff = startDate.difference(endDate, 'months');
  const months = diff % 12;
  const years = (diff - months) / 12;
  const timeWorked = `${years ? `${pluralize(years, 'yr', 's', '')} ` : ''}${
    months ? `${pluralize(months, 'mo', 's', '')}` : ''
  }`;

  const datesWorkedStart = startDate.toLocaleString({
    month: 'short',
    year: 'numeric',
  });

  const datesWorkedEnd =
    !workHistory?.end_date || workHistory?.currently_work_here
      ? 'Current'
      : endDate.toLocaleString({
          month: 'short',
          year: 'numeric',
        });

  const datesWorked = `${datesWorkedStart} - ${datesWorkedEnd}`;

  return {
    datesWorked,
    hasWorkHistory: true,
    timeWorked,
  };
};

export const ExperienceSummaryCard = ({
  position: inputPosition,
  workHistory: inputWorkHistory,
}: Props) => {
  const position = parseFragmentData(ExperienceSummaryCardPositionGql, inputPosition);
  const isHighDemand = position.incrementality === 'prioritize';

  const { datesWorked, hasWorkHistory, timeWorked } = getWorkHistoryInfo(inputWorkHistory);

  return (
    <Card>
      <HStack alignItems="center" justifyContent="space-between">
        <VStack>
          {hasWorkHistory && (
            <Label color="state.teal.secondary" size="xs">
              {timeWorked}
            </Label>
          )}
          <Label size="m">{position.display_name}</Label>
          {hasWorkHistory && <Text size="m">{datesWorked}</Text>}
          <Box mt={1}>
            <HStack gap={0.5}>
              {!hasWorkHistory && <InfoBadge label="Add details" />}
              {isHighDemand && <InfoBadge label="High demand" color="success" />}
            </HStack>
          </Box>
        </VStack>
        {/* PRO-2555 - open work experience form */}
        <Box onClick={() => console.warn('not implemented yet')}>
          <Icon name="calendar" />
        </Box>
      </HStack>
    </Card>
  );
};
